import { UIElement } from '../../../shared-components/components/ui-element.js';
import styles from './ui-series.css';

/**
 * @memberof ChartsComponents
 * @element ui-series
 * @augments {UIElement}
 * @alias UISeries
 * @classdesc Represents a class for <code>ui-series</code> element.
 * This is tag abstraction for Highchart series.
 * For more details visit: https://api.highcharts.com/class-reference/Highcharts.Series
 * @property {string} icon {@attr icon} Icon for series, same as glyphs is icon.
 * @property {string} data {@attr data} Semicolor-separated (;) data for series
 * @property {string} name {@attr name} Name (label) of the series.
 * @property {boolean} hidden {@attr hidden} Sets series hidden (inactive on chart),
 * but legend is still visible.
 * @example
 * <ui-series></ui-series>
 */
class UISeries extends UIElement {
    /**
     * @type {IProps}
     */
    static get props() {
        return {
            attributes: {
                name: String,
                icon: String,
                data: String,
                hidden: Boolean,
            },
        };
    }

    /**
     * Parses the data attribute.
     * @returns {object}
     * @private
     */
    parseDataAttribute() {
        const retval = [];
        const tmp = JSON.parse(this.data);
        tmp.forEach((i) => {
            let dataObj = {};
            if (Array.isArray(i)) {
                dataObj = i;
            } else {
                dataObj.y = i;
            }
            if (this.className) {
                dataObj.className = this.className;
            }
            retval.push(dataObj);
        });
        return retval;
    }

    /**
     * Converts points data to object.
     * @returns {object}
     * @private
     */
    getDataFromPoints() {
        const points = this.querySelectorAll('ui-point');
        return [].map.call(points, (point) => {
            // #bug: see method UIChart.prototype.mergeOptionsFromElement;
            // Object.setPrototypeOf(point, UIPoint.prototype);
            return point.getAttrs();
        });
    }

    /**
     * Checks that series has ui-point children.
     * @returns {boolean}
     * @private
     */
    hasPoints() {
        return this.querySelectorAll('ui-point').length > 0;
    }

    /**
     * Converts component's attributes to JSON object.
     * @returns {object}
     */
    toJSON() {
        const retval = {};
        for (let i = 0; i < this.attributes.length; ++i) {
            const name = this.attributes[i].name;
            retval[name] =
                name === 'data'
                    ? this.parseDataAttribute()
                    : this.attributes[i].nodeValue;
        }
        if (this.classList.length > 0) {
            retval.className = this.className;
            const cidx = this.getColorIndexFromClassName();
            if (cidx !== -1) {
                retval.colorIndex = cidx;
            }
        }
        if (this.hidden) {
            retval.visible = !this.hidden;
        }
        // TODO: append point dynamically to current data if exists?
        if (this.hasPoints()) {
            retval.data = this.getDataFromPoints();
        }
        return retval;
    }

    /**
     * Gets the last color index from highcharts-color-{n} css classname.
     * @returns {number}
     * @private
     */
    getColorIndexFromClassName() {
        let index = -1;
        [].forEach.call(this.classList, (klass) => {
            const c = parseInt(klass.replace(/highcharts-color-/g, ''));
            if (!isNaN(c)) {
                index = c;
            }
        });
        return index;
    }
}

UISeries.defineElement('ui-series', styles);
export { UISeries };
