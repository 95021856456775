import { UIChart } from '../chart/ui-chart.js';
import { UIIcon } from '../../../shared-components/components/icon/ui-icon.js';
import styles from './ui-chart-pie.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-pie
 * @augments {UIChart}
 * @alias UIChartPie
 * @classdesc Represents a class for <code>ui-chart-pie</code> element.
 * Draws pie chart.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-pie units="%">
 *   <ui-series name="Swedbank cards">
 *     <ui-point y="53" name="Swedbank cards"></ui-point>
 *     <ui-point y="20" name="SEB cards"></ui-point>
 *     <ui-point y="27" name="Other cards"></ui-point>
 *   </ui-series>
 * </ui-chart-pie>
 */
class UIChartPie extends UIChart {
    static get HIDE_LABEL_PERCENT_THRESHOLD() {
        return 5;
    }

    get type() {
        return 'pie';
    }

    /**
     * Custom label formatter for data labels with icons.
     * @param {UIChart} current
     * @param {boolean} percents
     * @returns {string}
     */
    static customDataLabelFormatter(current, percents) {
        let retval = '';
        current.getOptions().series.map((series) => {
            if (series.data[this.point.index].icon) {
                current.setAttribute('with-icons', true);
                if (
                    this.percentage >=
                        UIChartPie.HIDE_LABEL_PERCENT_THRESHOLD ||
                    (this.percentage === 0 && series.data.length === 1)
                ) {
                    const text = percents
                        ? Math.round(
                              current.calculatePointPercentage(
                                  this.point.series,
                                  this.y
                              )
                          ) + '%'
                        : this.y + current.units;
                    const customLabel = current.createElement({
                        tagName: 'div',
                        classList: {
                            'ui-chart-pie__data-label': true,
                        },
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: series.data[this.point.index].icon,
                                    color: UIIcon.colors.WHITE,
                                },
                            },
                            {
                                tagName: 'span',
                                children: [text],
                            },
                        ],
                    });
                    retval += customLabel.outerHTML;
                }
            } else {
                retval += current.createElement({
                    tagName: 'span',
                    classList: {
                        'piechart-custom-datalabel': true,
                        'ui-chart-pie__data-label': true,
                    },
                    children: [
                        {
                            tagName: 'span',
                            classList: {
                                'piechart-custom-datalabel-name': true,
                            },
                            children: [this.point.name],
                        },
                        {
                            tagName: 'span',
                            classList: {
                                'piechart-custom-datalabel-value': true,
                            },
                            children: [String(this.point.y), current.units],
                        },
                    ],
                }).outerHTML;
            }
        });
        return retval;
    }

    /**
     * Sets correctly centered position into sector for custom labels in pie chart.
     */
    static customLabelPlacer() {
        [].forEach.call(this.series, (series) => {
            [].forEach.call(series.data, (point) => {
                if (point.dataLabel && point.dataLabel.div) {
                    const bbox = point.dataLabel.div.querySelector(
                        '.ui-chart-pie__data-label'
                    );
                    const iconWidthOffset = bbox
                        ? bbox.getBoundingClientRect().width / 2
                        : 0;
                    const chart = point.series.chart;
                    const cx = chart.series[0].center[0];
                    const cy = chart.series[0].center[1];
                    const mr = (point.shapeArgs.r + point.shapeArgs.innerR) / 2;
                    const angle = point.angle;
                    const { x, y } = UIChart.calculateCircleCoords(
                        mr,
                        angle,
                        0,
                        0
                    );
                    const dx = cx + x;
                    const dy = cy + y;
                    point.dataLabel.attr({
                        x: dx - iconWidthOffset,
                        y: dy,
                    });
                }
            });
        });
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.mergeOptionsFromElement();
        const currentChart = this.instance;
        this.draw({
            chart: {
                events: {
                    render: function () {
                        UIChartPie.customLabelPlacer.call(this);
                    },
                },
            },
            plotOptions: {
                series: {
                    showInLegend: true,
                    dataLabels: {
                        enabled: true,
                        useHTML: true,
                        align: 'center',
                        overflow: 'justify',
                        distance: 0,
                        formatter: function () {
                            return UIChartPie.customDataLabelFormatter.call(
                                this,
                                currentChart
                            );
                        },
                    },
                },
            },
            legend: {
                x: -10, // -10 due to default point is hidden
            },
        });
    }
}

UIChartPie.defineElement('ui-chart-pie', styles);
export { UIChartPie };
