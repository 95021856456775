import { UIChartColumn } from '../../components/column/ui-chart-column.js';
import { whenDOMReady } from '../../../shared-components/global/ui-helpers.js';

/**
 * @classdesc Class for ui-chart-column stack-labels.
 * @alias UIChartColumnStackLabels
 * @implements {IComponentPlugin}
 * @param {HTMLElement} target
 * @classdesc Plugin for UIChartColumn which set icon to the stack label on the top.
 * Works with 'ui-point'/'ui-series' that have attribute
 * 'stack-icon'/'stack-icon-color'/'stack-icon-id'(ids only with 'ui-point').
 * NB: icons can be set only in the first series item.
 * @example
 * <ui-chart-column>
 *   <ui-series name="gain" class="highcharts-color-13">
 *     <ui-point y="5.61" stack-icon="food" stack-icon-color="orange"></ui-point>
 *     <ui-point y="8.22"></ui-point>
 *     <ui-point y="8.22" stack-icon="envelope"></ui-point>
 *   </ui-series>
 *   <ui-series name="payments" class="highcharts-color-2">
 *     <ui-point y="123.05"></ui-point>
 *     <ui-point y="80.22"></ui-point>
 *     <ui-point y="80.22"></ui-point>
 *   </ui-series>
 * </ui-chart-column>
 */
class UIChartColumnStackLabels {
    constructor(target) {
        /**
         * @type {ChartsComponents.UIChartColumn}
         */
        this.target = target;
    }

    /**
     * Builds icon on the top of the stack
     * @param {UIChart} current
     * @returns {string | undefined}
     */
    buildIconedStackLabel(current) {
        const firstSeries = current.chart.series && current.chart.series[0];

        if (!firstSeries) {
            return;
        }

        const isSeriesWithIcon =
            firstSeries.userOptions && firstSeries.userOptions['stack-icon'];
        const isPointWithIcon =
            firstSeries.points &&
            firstSeries.points[this.x] &&
            firstSeries.points[this.x].options['stack-icon'];
        let options;

        if (isSeriesWithIcon) {
            options = firstSeries.userOptions;
        } else if (isPointWithIcon) {
            options = firstSeries.points[this.x].options;
        } else {
            return;
        }

        return UI.element`
        <ui-icon
          glyph="${options['stack-icon']}"
          color="${options['stack-icon-color'] || null}"
          id="${options['stack-icon-id'] || null}"></ui-icon>
    `.outerHTML;
    }

    /**
     * @inheritDoc
     */
    render() {}

    /**
     * @inheritDoc
     */
    hydrate() {
        const chart = this.target;

        // Needed because of async ui-chart drawing
        whenDOMReady(() => {
            this.target.chart.update({
                yAxis: {
                    stackLabels: {
                        enabled: true,
                        useHTML: true,
                        formatter: function () {
                            return UIChartColumnStackLabels.prototype.buildIconedStackLabel.call(
                                this,
                                chart
                            );
                        },
                    },
                },
            });
        });
    }
}

UIChartColumn.registerPlugin('stack-labels', UIChartColumnStackLabels);

export { UIChartColumnStackLabels };
