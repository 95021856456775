import { UIChart } from '../chart/ui-chart.js';
import styles from './ui-chart-stock.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-stock
 * @augments {UIChart}
 * @alias UIChartStock
 * @classdesc Represents a class for <code>ui-chart-line</code> element.
 * Draws line type chart.
 * @example
 * <ui-chart-line></ui-chart-line>
 */
class UIChartStock extends UIChart {
    /**
     * @type {string}
     */
    get type() {
        return 'line';
    }

    /**
     * @type {boolean}
     */
    get stock() {
        return true;
    }

    /**
     * @type {string}
     */
    get preset() {
        return 'interactive';
    }

    /**
     * @type {Highcharts.Options}
     */
    get chartConfig() {
        return {
            legend: {
                enabled: false,
            },
            xAxis: {
                visible: true,
            },
            yAxis: {
                visible: true,
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                        radius: 0,
                        states: {
                            hover: {
                                enabled: true,
                                radius: 4,
                            },
                        },
                    },
                },
            },
            navigator: {
                series: {
                    marker: {
                        enabled: false,
                    },
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 767,
                        },
                        chartOptions: {
                            rangeSelector: {
                                inputPosition: {
                                    align: 'left',
                                },
                            },
                        },
                    },
                ],
            },
        };
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.draw(this.chartConfig);
    }
}

UIChartStock.defineElement('ui-chart-stock', styles);
export { UIChartStock };
