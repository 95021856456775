import {
    createElement,
    getAttributes,
    insertElements,
    prerenderElementsTree,
} from './render-api.js';

/**
 * Mutates attributes object.
 * @memberof UiHelpers
 * @param {Record<string, string>} oldAttributes
 * @param {Record<string, string>} newAttributes
 * @returns {Function}
 */
const mutateAttributesTo = (oldAttributes, newAttributes) => {
    const patches = [];
    const ignoredAttributes = ['state', 'expanded'];
    if (oldAttributes['data-transition']) {
        ignoredAttributes.push('class', 'data-transition');
    }

    for (const oldKey in oldAttributes) {
        if (!oldAttributes.hasOwnProperty(oldKey)) {
            continue;
        }
        if (ignoredAttributes.indexOf(oldKey) > -1) {
            continue;
        }
        if (!(oldKey in newAttributes)) {
            patches.push(
                (function (attr) {
                    return (node) => node.removeAttribute(attr);
                })(oldKey)
            );
        }
    }

    for (const key in newAttributes) {
        if (!newAttributes.hasOwnProperty(key)) {
            continue;
        }
        if (ignoredAttributes.indexOf(key) > -1) {
            continue;
        }
        if (newAttributes[key] !== oldAttributes[key]) {
            patches.push(
                (function (attr) {
                    return (node) =>
                        node.setAttribute(attr, newAttributes[attr]);
                })(key)
            );
        }
    }

    return (node) => patches.forEach((patch) => patch(node));
};

/**
 * It will create a path to mutate old node element to new node.
 * @memberof UiHelpers
 * @param {Element} oldNode
 * @param {Element} newNode
 * @param {boolean} [immediately] - indicate if patch should be applied immediately
 * @returns {Function}
 */
const mutateTo = (oldNode, newNode, immediately) => {
    const shouldReplaceNode =
        oldNode.tagName && newNode.tagName
            ? oldNode.tagName !== newNode.tagName
            : oldNode.nodeType !== newNode.nodeType ||
              oldNode.nodeValue !== newNode.nodeValue;

    if (shouldReplaceNode) {
        return (node) => {
            const parentElement = node.parentNode;
            if (parentElement) {
                parentElement.insertBefore(newNode, node);
                parentElement.removeChild(node);
                return newNode;
            }
        };
    }

    const patches = [];

    if (oldNode.tagName && newNode.tagName) {
        patches.push(
            mutateAttributesTo(getAttributes(oldNode), getAttributes(newNode)),
            mutateChildrenTo(
                [].slice.call(oldNode.childNodes),
                [].slice.call(newNode.childNodes)
            )
        );
    }
    const result = (node) => patches.forEach((patch) => patch(node));

    if (immediately) {
        prerenderElementsTree(newNode, true);
        result(oldNode);
    }

    return result;
};

/**
 * Mutates children object.
 * @memberof UiHelpers
 * @param {NodeList | Array<Node>} oldChildren
 * @param {NodeList | Array<Node>} newChildren
 * @param {Function} [smoothRemove]
 * @param {Function} [smoothAppend]
 * @returns {Function}
 */
const mutateChildrenTo = (
    oldChildren,
    newChildren,
    smoothRemove,
    smoothAppend
) => {
    if (!Array.isArray(oldChildren)) {
        oldChildren = [].slice.call(oldChildren);
    }
    if (!Array.isArray(newChildren)) {
        newChildren = [].slice.call(newChildren);
    }

    const patches = [];

    const createAppendHandler = (parentNode) => {
        return (subNode) => {
            if (smoothAppend) {
                return smoothAppend(subNode, parentNode);
            }
            parentNode.appendChild(subNode);
        };
    };

    const createRemoveHandler = (parentNode) => {
        return (subNode) => {
            if (!subNode || parentNode !== subNode.parentElement) {
                return;
            }
            if (smoothRemove) {
                return smoothRemove(subNode);
            }
            parentNode.removeChild(subNode);
        };
    };

    const createMutateHandler = (oldNode, newNode) => {
        return () => {
            mutateTo(oldNode, newNode)(oldNode);
        };
    };

    const createSortHandler = (oldNode, index) => {
        return (parentNode) => {
            if (!parentNode) {
                return;
            }
            parentNode.insertBefore(oldNode, parentNode.childNodes[index]);
        };
    };

    const oldMap = {};

    oldChildren.forEach((node) => {
        const key = node && node.tagName ? node.getAttribute('key') : null;
        if (key) {
            oldMap[key] = node;
        }
    });

    const sortPatches = [];

    newChildren.forEach((node, index) => {
        const key = node && node.tagName ? node.getAttribute('key') : null;
        if (key) {
            if (oldMap[key]) {
                const oldIndex = oldChildren.indexOf(oldMap[key]);
                if (oldIndex === index) {
                    return;
                }
                if (oldChildren.length - 1 < index) {
                    oldChildren.length = index + 1;
                }
                oldChildren.splice(oldIndex, 1, undefined);
                oldChildren.splice(index, 0, oldMap[key]);
                sortPatches.push(createSortHandler(oldMap[key], index));
            }
        }
    });

    if (!oldChildren.length) {
        patches.push((node) => {
            [].forEach.call(newChildren, createAppendHandler(node));
        });
    } else if (newChildren.length < oldChildren.length) {
        const toRemove = [].slice.call(oldChildren, newChildren.length);
        patches.push((node) => {
            toRemove.forEach(createRemoveHandler(node));
        });
    } else if (newChildren.length > oldChildren.length) {
        const toAdd = newChildren.slice(oldChildren.length);
        patches.push((node) => {
            toAdd.forEach(createAppendHandler(node));
        });
    }

    for (
        let index = 0;
        index < Math.min(newChildren.length, oldChildren.length);
        index++
    ) {
        patches.push(
            (function (index) {
                if (!oldChildren[index]) {
                    sortPatches.push(
                        createSortHandler(newChildren[index], index)
                    );
                    return (parentNode) => {
                        createAppendHandler(parentNode)(newChildren[index]);
                    };
                }
                return createMutateHandler(
                    oldChildren[index],
                    newChildren[index]
                );
            })(index)
        );
    }

    return (node) => {
        patches.forEach((patch) => patch(node));
        sortPatches.forEach((patch) => patch(node));
    };
};

/**
 * @memberof UiHelpers
 * @param {HTMLElement} targetNode
 * @param {Array<IElementConfig | Node> | DocumentFragment} source
 * @param {Function} [smoothRemove]
 * @param {Function} [smoothAppend]
 * @returns {HTMLElement}
 */
const rebuildChildren = (targetNode, source, smoothRemove, smoothAppend) => {
    const elements =
        source instanceof DocumentFragment ? [...source.childNodes] : source;
    const newNodes = elements.reduce((collection, entry) => {
        return collection.concat(
            [].concat(entry).map((item) => {
                if (item instanceof Node) {
                    return item;
                }
                const element = createElement(item);
                prerenderElementsTree(element, true);
                return element;
            })
        );
    }, []);

    const oldNodes = [].filter.call(targetNode.childNodes, (node) => {
        return node.tagName && !node.classList.contains('-removing');
    });
    if (!oldNodes.length) {
        insertElements(targetNode, newNodes);
    } else {
        mutateChildrenTo(
            oldNodes,
            newNodes,
            smoothRemove,
            smoothAppend
        )(targetNode);
    }

    return targetNode;
};

/**
 * @memberof UiHelpers
 * @param {HTMLElement} node
 * @param {IElementConfig | Node} newNode
 * @returns {HTMLElement}
 */
const rebuild = (node, newNode) => {
    const targetNode =
        newNode instanceof Node ? newNode : createElement(newNode);
    prerenderElementsTree(targetNode, true);
    mutateTo(node, targetNode, true);
    return node;
};

export {
    mutateTo,
    mutateChildrenTo,
    mutateAttributesTo,
    rebuildChildren,
    rebuild,
};
