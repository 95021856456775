import { UIElement } from '../../../shared-components/components/ui-element.js';
import styles from './ui-point.css';

/**
 * @memberof ChartsComponents
 * @element ui-point
 * @augments {UIElement}
 * @alias UIPoint
 * @classdesc Represents a class for <code>ui-point</code> element.
 * This is a point for series object, tag representations.
 * For more details visit: https://api.highcharts.com/class-reference/Highcharts.Point
 * @property {object} attrs Holds attributes.
 * @property {number|string} y Values of the point.
 * @property {string} name Name of the point.
 * @property {string} icon Icon for the point where supported.
 * @example
 * <ui-point x="12" y="123" z="0" name="boo"></ui-point>
 * point.getAttrs() will produce
 * {
 *   x: 12,
 *   y: 123,
 *   z: 0,
 *   name: "boo"
 * }
 */
class UIPoint extends UIElement {
    /**
     * If attribute is a number the return it as number, otherwise string.
     * @param {string} attr
     * @returns {number}
     * @private
     */
    getAttrAsType(attr) {
        return isNaN(Number(attr)) ? attr : Number(attr);
    }

    /**
     * Reflect attributes to properties.
     * Except rendered and state attributes.
     * @private
     */
    attributesToProperty() {
        for (let i = 0; i < this.attributes.length; ++i) {
            const name = this.attributes[i].name;
            const value = this.getAttrAsType(this.attributes[i].nodeValue);
            if (name === 'state') {
                continue;
            }
            if (name === 'hidden') {
                this.attrs.visible = false;
            }
            if (name === 'class') {
                this.attrs.className = value;
            } else {
                this.attrs[name] = value;
            }
        }
    }

    /**
     * Gets the attributes as JSON object.
     * @returns {object}
     */
    getAttrs() {
        this.attrs = {};
        this.attributesToProperty();
        return this.attrs;
    }
}

UIPoint.defineElement('ui-point', styles);
export { UIPoint };
