export const PFMMappings = {
    category_1: {
        id: 1,
        icon: 'redesign-wallet',
        name: 'income',
        color: '--ui-color-pfm-income',
        sortIndex: 20,
        sortTemplate: [2, 809, 121, 123, 122, 138, 4, 3, 7, 6, 145, 5, 268, 1],
    },
    category_13: {
        icon: 'redesign-house',
        name: 'home',
        color: '--ui-color-pfm-home',
        sortIndex: 6,
        sortTemplate: [
            15, 16, 801, 802, 327, 18, 27, 26, 20, 21, 29, 23, 25, 105, 13,
        ],
    },
    category_32: {
        icon: 'redesign-car',
        name: 'transport',
        color: '--ui-color-pfm-car',
        sortIndex: 7,
        sortTemplate: [34, 36, 35, 288, 37, 38, 32],
    },
    category_40: {
        icon: 'redesign-teddy-bear',
        name: 'children',
        color: '--ui-color-pfm-children',
        sortIndex: 9,
        sortTemplate: [41, 46, 43, 42, 44, 45, 47, 803, 40],
    },
    category_48: {
        icon: 'redesign-heart',
        name: 'health',
        color: '--ui-color-pfm-health',
        sortIndex: 12,
        sortTemplate: [66, 67, 49, 72, 51, 48],
    },

    category_54: {
        icon: 'redesign-folder-open',
        name: 'expences',
        color: '--ui-color-bark-60',
        sortIndex: 17,
        sortTemplate: [59, 56, 808, 804, 124, 810, 54],
    },
    category_64: {
        icon: 'redesign-shopping-cart',
        name: 'shopping',
        color: '--ui-color-pfm-services',
        sortIndex: 8,
        sortTemplate: [78, 65, 61, 91, 113, 69, 82, 114, 24, 64],
    },
    category_70: {
        icon: 'redesign-face-laugh',
        name: 'leisure',
        color: '--ui-color-pfm-leisure',
        sortIndex: 11,
        sortTemplate: [
            75, 52, 95, 79, 104, 258, 199, 262, 198, 202, 200, 265, 267, 70,
        ],
    },
    category_86: {
        icon: 'redesign-book-open',
        name: 'education',
        color: '--ui-color-pfm-education',
        sortIndex: 13,
        sortTemplate: [88, 89, 87, 86],
    },
    category_96: {
        icon: 'redesign-sun',
        name: 'vacation',
        color: '--ui-color-pfm-vacation',
        sortIndex: 14,
        sortTemplate: [98, 341, 340, 339, 342, 805, 96],
    },
    category_101: {
        icon: 'redesign-coins-ontop-of-line',
        name: 'investments',
        color: '--ui-color-pfm-investments',
        sortIndex: 16,
        sortTemplate: [102, 132, 134, 103, 106, 101],
    },
    category_112: {
        icon: 'redesign-question-mark',
        name: 'unknown',
        color: '--ui-color-pfm-unknown',
        sortIndex: 19,
        sortTemplate: [216, 229, 112],
    },
    category_116: {
        icon: 'redesign-fork-knife',
        name: 'food',
        color: '--ui-color-pfm-food',
        sortIndex: 5,
        sortTemplate: [14, 84, 71, 83, 806, 81, 116],
    },
    category_210: {
        icon: 'redesign-x-mark',
        name: 'excluded',
        color: '--ui-color-pfm-excluded',
        sortIndex: 18,
        sortTemplate: [212, 210],
    },
    category_307: {
        icon: 'redesign-umbrella',
        name: 'insurance',
        color: '--ui-color-pfm-insurance',
        sortIndex: 15,
        sortTemplate: [17, 33, 50, 807, 307],
    },
    category_105: {
        icon: 'redesign-paw',
        name: 'pets',
        color: '--ui-color-redesign-seafoam',
        sortIndex: 10,
        sortTemplate: [151, 152, 153, 154, 155, 105],
    },

    category_100: {
        icon: 'redesign-question-mark',
        name: 'unknown',
        color: '--ui-color-pfm-no-expenses',
        sortIndex: 21,
        sortTemplate: [],
    },
};
