import { UIElement } from '../ui-element.js';
import styles from './ui-data.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIData
 * @element ui-data
 * @classdesc Represents a class for <code>ui-data</code> element.
 * Defaults abstract element which holds the data. Also
 * Makes ajax request for resources specified in 'src' attribute
 * or read inline from element body JSON, CSV, Plain Text and
 * other formats.
 * @property {string} [src] {@attr src} URL source of data.
 * @property {string} [type="text/plain"] {@attr type} application/json, text/csv, text/plain etc.
 * @property {string} [method="GET"] {@attr method} HTTP method of request.
 * At the momnet only GET method is supported.
 * @slot
 * @example
 * <ui-data src="http://www.swedbank.com/page.html></ui-data>
 * <ui-data src="http://www.swedbank.com/page.json type="application/json"></ui-data>
 * <ui-data type="application/json">{
 *   "name": "Salomon",
 *   "age": 34,
 *   "job": "Developer"
 * }</ui-data>
 *
 * <ui-data type="text/csv">
 *   Name,Age,Job
 *   Bill,34,Cowboy
 *   Batman,120,Superhero
 * </ui-data>
 */
class UIData extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                src: { type: String, default: '' },
                type: { type: String, default: 'text/plain' },
                method: { type: String, default: 'GET' },
            },
        };
    }

    /**
     * Error handler.
     * @returns {Function}
     */
    get errorHandler() {
        if (!this._errorHandler) {
            return this.defaultErrorHandler;
        }
        return this._errorHandler;
    }

    /**
     * @param {Function} handler
     */
    set errorHandler(handler) {
        this._errorHandler = handler;
    }

    /**
     * Default error handler.
     * @param {Error} err
     * @private
     */
    defaultErrorHandler(err) {
        const ERROR_MSG =
            this.constructor.name + ': cannot fetch ' + this.src + '.';
        console.error(err, ERROR_MSG);
    }

    /**
     * Resolves XHR responseType string based on type.
     * @returns {string}
     * @private
     */
    get _resolveContentType() {
        switch (this.type) {
            case 'application/json':
            case 'text/json':
                return 'json';
            default:
                return 'text';
        }
    }

    /**
     * Checks valid AJAX status
     * @param {number} status
     * @returns {boolean}
     */
    static isStatusValid(status) {
        return status >= 200 && status < 400;
    }

    /**
     * Fetches content from remove server's request (AJAX).
     * @private
     */
    fetchRemoteData() {
        fetch(this.src, {
            method: this.method,
            headers: { 'Content-Type': this.type },
        })
            .then((response) => {
                if (UIData.isStatusValid(response.status)) {
                    const call =
                        this.type === 'application/json'
                            ? response.json()
                            : response.text();
                    return call.then((data) => {
                        this._fetchedData = data;
                        this.callback(data);
                    });
                } else {
                    this.errorHandler(response);
                }
            })
            .catch((e) => {
                this.errorHandler(e);
            });
    }

    /**
     * Fetches the data from content which is put into this element innerHTML.
     * @returns {object | string}
     * @private
     */
    fetchElemBodyData() {
        if (this._resolveContentType === 'json') {
            if (this.innerHTML.trim() === '') {
                this._fetchedData = {};
            } else {
                try {
                    this._fetchedData = JSON.parse(this.innerHTML);
                } catch (e) {
                    console.error(e);
                    this._fetchedData = {};
                }
            }
        } else {
            this._fetchedData = this.innerHTML.trim();
        }
        if (this.callback) {
            this.callback(this._fetchedData);
        }
        return this.getFetchedData();
    }

    /**
     * Gets the fetched data.
     * @returns {undefined|object|string}
     */
    getFetchedData() {
        return this._fetchedData;
    }

    /**
     * Fetches the data from URL if 'src' attribute is provided,
     * otherwise from elements innerHTML.
     * @param {Function} cb Callback after data fetching.
     * @returns {object | string}
     */
    get(cb) {
        if (cb) {
            this.callback = cb;
        }
        return this.src ? this.fetchRemoteData() : this.fetchElemBodyData();
    }
}

UIData.defineElement('ui-data', styles);
export { UIData };
