import { UIChartArea } from '../area/ui-chart-area.js';
import styles from './ui-chart-areaspline.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-areaspline
 * @augments {UIChartArea}
 * @alias UIChartAreaSpline
 * @classdesc Represents a class for <code>ui-chart-areaspline</code> element.
 * Draws areaspline type chart.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-areaspline preset="interactive">
 *   <ui-series data="[10, 25, 50, 33, 43, 98]"></ui-series>
 * </ui-chart-areaspline>
 */
class UIChartAreaSpline extends UIChartArea {
    get type() {
        return 'areaspline';
    }
}

UIChartAreaSpline.defineElement('ui-chart-areaspline', styles);
export { UIChartAreaSpline };
