import { UIChart } from '../chart/ui-chart.js';
import styles from './ui-chart-solidgauge.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-solidgauge
 * @augments {UIChart}
 * @alias UIChartSolidGauge
 * @classdesc Represents a class for <code>ui-chart-solidgauge</code> element.
 * Draws solid gauge type chart.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-solidgauge units="EUR">
 *   <ui-series>
 *     <ui-point name="Euros" y="225"></ui-point>
 *   </ui-series>
 * </ui-chart-solidgauge>
 */
class UIChartSolidGauge extends UIChart {
    get type() {
        return 'solidgauge';
    }

    /**
     * Sets position on animation event.
     * @param {object} c
     * @private
     */
    setAnimationStep(c) {
        requestAnimationFrame((ms) => {
            if (c.chart && this.end && c.chart.series[0].points[0].dataLabel) {
                const dy = UIChart.remap(this.end, -Math.PI, 0, c.min, c.max);
                const label =
                    c.chart.series[0].points[0].dataLabel.options.formatter.call(
                        {
                            point: {
                                y: Math.round(dy),
                                shapeArgs: {
                                    r: this.r,
                                    end: this.end,
                                },
                            },
                        }
                    );
                c.chart.series[0].points[0].dataLabel.attr({
                    text: label,
                });
            }
        });
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.mergeOptionsFromElement();
        if (this.opts && this.opts.yAxis) {
            this.min = this.opts.yAxis.min || 0;
            this.max = this.opts.yAxis.max || 100;
        } else {
            this.min = 0;
            this.max = 100;
        }

        const currentChart = this.instance;
        const units = this.units;
        const params = {
            subtitle: {
                align: 'center',
                y: 12,
            },
            pane: {
                size: '100%',
                center: ['50%', '85%'],
                startAngle: -90,
                endAngle: 90,
                background: {
                    innerRadius: '75%',
                    outerRadius: '100%',
                    shape: 'arc',
                },
            },
            xAxis: {
                visible: true,
            },
            yAxis: {
                visible: true,
                tickPositions: [this.min, this.max],
                minorTickInterval: null,
                allowDecimals: true,
                labels: {
                    y: 16,
                    useHTML: true,
                    distance: -11,
                    formatter: function () {
                        let startLabel = '';
                        let endLabel = '';
                        if (this.chart.xAxis[0].categories) {
                            startLabel = this.chart.xAxis[0].categories[0];
                            endLabel = this.chart.xAxis[0].categories[1];
                        }
                        return (
                            '<div class="highcharts-data-label solidgauge-yaxis-numeric-label">' +
                            this.value +
                            '' +
                            units +
                            '</div>' +
                            '<div class="solidgauge-yaxis-label">' +
                            (this.isFirst ? startLabel : '') +
                            (this.isLast ? endLabel : '') +
                            '</div>'
                        );
                    },
                },
            },
            plotOptions: {
                series: {
                    animation: {
                        step: function () {
                            return currentChart.setAnimationStep.call(
                                this,
                                currentChart
                            );
                        },
                    },
                    dataLabels: {
                        useHTML: true,
                        format: '{point.y}' + this.units,
                        formatter: function () {
                            const { x, y } = UIChart.calculateCircleCoords(
                                this.point.shapeArgs.r,
                                this.point.shapeArgs.end,
                                0.2,
                                0.2
                            );
                            return (
                                '<span style="position:absolute;' +
                                'top:' +
                                Math.round(y) +
                                'px;' +
                                'left:' +
                                Math.round(x) +
                                'px;">' +
                                this.point.y +
                                '' +
                                units +
                                '</span>'
                            );
                        },
                    },
                    innerRadius: '75%',
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 500,
                        },
                        chartOptions: {
                            chart: {
                                height: 300,
                            },
                            pane: {
                                center: ['50%', '50%'],
                            },
                            plotOptions: {
                                series: {
                                    dataLabels: {
                                        formatter: () => void 0,
                                        y: -30,
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        };
        this.draw(params);
    }
}

UIChartSolidGauge.defineElement('ui-chart-solidgauge', styles);
export { UIChartSolidGauge };
