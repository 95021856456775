import { UIChartLine } from '../line/ui-chart-line.js';
import styles from './ui-chart-area.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-area
 * @augments {UIChartLine}
 * @alias UIChartArea
 * @classdesc Represents a class for <code>ui-chart-area</code> element.
 * Draws area type chart.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-area preset="interactive">
 *   <ui-series data="[10, 25, 50, 33, 43, 98]"></ui-series>
 * </ui-chart-area>
 */
class UIChartArea extends UIChartLine {
    get type() {
        return 'area';
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.draw({
            xAxis: {
                tickmarkPlacement: 'on',
            },
            tooltip: {
                enabled: true,
            },
            plotOptions: {
                series: {
                    pointPlacement: 'on',
                    enableMouseTracking: true,
                    dataLabels: {
                        enabled: false,
                    },
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true,
                            },
                        },
                    },
                    legendSymbol: 'rectangle',
                },
            },
        });
    }
}

UIChartArea.defineElement('ui-chart-area', styles);
export { UIChartArea };
