import { UIChart } from '../chart/ui-chart.js';
import styles from './ui-chart-column.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-column
 * @augments {UIChart}
 * @alias UIChartColumn
 * @classdesc Represents a class for <code>ui-chart-column</code> element.
 * Draws column type chart.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-column preset="interactive">
 *   <ui-series data="[10, 25, 50, 33, 43, 98]"></ui-series>
 *   <ui-series data="[77, 3, 32, 33 , 5.4]"></ui-series>
 * </ui-chart-column>
 */
class UIChartColumn extends UIChart {
    get type() {
        return 'column';
    }

    /**
     * Formars X-axis labels for column type chart.
     * @param {UIChart} current
     * @returns {string}
     */
    formatXAxisLabels(current) {
        const idx = this.pos;
        let retval = '';
        const options = current.getOptions();
        if (!options.series) {
            return '';
        }
        const series = options.series[0];
        if (series && series.data && series.data[0].icon) {
            const icon = current.createElement({
                tagName: 'ui-icon',
                attributes: {
                    glyph: series.data[idx].icon,
                },
            });
            if (
                options.plotOptions.series.colorByPoint &&
                this.chart.series[0]
            ) {
                icon.className =
                    'highcharts-color-' +
                    (this.chart.userOptions.series[0].data[idx].colorIndex ||
                        0);
            } else {
                icon.className = 'highcharts-color-' + idx;
            }
            if (series.data[idx].className) {
                icon.className = series.data[idx].className;
            }
            retval += '<div class="highcharts-icon-label">' + icon.outerHTML;
        }
        retval += '<span>';
        if (series.data[idx] && series.data[idx].name) {
            retval += series.data[idx].name;
        }
        if (this.axis.categories && this.axis.categories[idx]) {
            retval += this.axis.categories[idx];
        }
        retval += '</span>';
        if (current.opts.xAxis.icons && current.opts.xAxis.icons[idx]) {
            retval += '</div>';
        }
        return retval;
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.redetachChildNodes();
        const currentChart = this.instance;
        this.draw({
            plotOptions: {
                series: {
                    enableMouseTracking: false,
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'allow',
                        format: '{point.y}' + this.units,
                    },
                },
            },
            xAxis: {
                tickWidth: 0,
                labels: {
                    formatter: function () {
                        return UIChartColumn.prototype.formatXAxisLabels.call(
                            this,
                            currentChart
                        );
                    },
                },
            },
        });
    }
}

UIChartColumn.defineElement('ui-chart-column', styles);
export { UIChartColumn };
