/**
 * @memberof SharedComponents
 * @classdesc Mapping for custom element classes and tag names.
 * @class TagRegistry
 * @alias tagRegistry
 */
class TagRegistry {
    constructor() {
        this.tagRegistryMap = new Map();
    }

    /**
     * Gets tagName from element class
     * @param {UIElement} element
     * @returns {string}
     */
    get(element) {
        return this.tagRegistryMap.get(element);
    }

    /**
     * Sets tagName to registry from element class
     * @param {CustomElementConstructor} element
     * @param {string} tagName
     */
    set(element, tagName) {
        this.tagRegistryMap.set(element, tagName);
    }
}

export const tagRegistry = new TagRegistry();
