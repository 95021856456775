import { UIChartColumn } from '../../components/column/ui-chart-column.js';
import { whenDOMReady } from '../../../shared-components/global/ui-helpers.js';

/**
 * @classdesc Class for ui-chart-column fix-overlap-labels.
 * @alias UIChartFixOverlapLabels
 * @implements {IComponentPlugin}
 * @param {HTMLElement} target
 */
class UIChartFixOverlapLabels {
    constructor(target) {
        this.target = target;
    }

    /**
     * Rectangle intersection check.
     * @param {number} x1
     * @param {number} y1
     * @param {number} w1
     * @param {number} h1
     * @param {number} x2
     * @param {number} y2
     * @param {number} w2
     * @param {number} h2
     * @returns {boolean}
     * @private
     */
    static intersectRect(x1, y1, w1, h1, x2, y2, w2, h2) {
        return !(x2 > x1 + w1 || x2 + w2 < x1 || y2 > y1 + h1 || y2 + h2 < y1);
    }

    /**
     * Moves label down.
     */
    fixOverlapLabels() {
        const chart = this.target.chart;
        const series = chart.series;
        const mpl = chart.userOptions.plotOptions.series.minPointLength || 13;
        for (let i = 1; i < series.length; ++i) {
            for (let j = 0; j < series.length; ++j) {
                if (!series[i - 1].data[j] || !series[i].data[j]) {
                    return;
                }

                const label1 = series[i - 1].data[j].dataLabel;
                const label2 = series[i].data[j].dataLabel;
                if (
                    label1 &&
                    label2 &&
                    label1.placed &&
                    label2.placed &&
                    label1.newOpacity !== 0 &&
                    label2.newOpacity !== 0
                ) {
                    const pos1 = label1.alignAttr;
                    const pos2 = label2.alignAttr;
                    const parent1 = label1.parentGroup; // Different panes have different positions
                    const parent2 = label2.parentGroup;
                    // Substract the padding if no background or border
                    const padding = 2 * (label1.box ? 0 : label1.padding);
                    const isIntersecting =
                        UIChartFixOverlapLabels.intersectRect(
                            pos1.x + parent1.translateX,
                            pos1.y + parent1.translateY,
                            label1.width - padding,
                            label1.height - padding,
                            pos2.x + parent2.translateX,
                            pos2.y + parent2.translateY,
                            label2.width - padding,
                            label2.height - padding
                        );
                    if (isIntersecting) {
                        const y1 =
                            Number(label1.element.getAttribute('y')) || 0;
                        const y2 =
                            Number(label2.element.getAttribute('y')) || 0;
                        const k1 = series[i - 1].data[j].negative ? -1 : 1;
                        const k2 = series[i].data[j].negative ? -1 : 1;
                        label1.element.setAttribute(
                            'y',
                            (y2 - mpl * k2) / 2.0 + mpl - k1 * 2
                        );
                        label2.element.setAttribute(
                            'y',
                            (y1 + mpl * k1) / 2.0 + mpl - k2 * 2
                        );
                    }
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    render() {}

    /**
     * @inheritDoc
     */
    hydrate() {
        // Needed because of async ui-chart drawing
        whenDOMReady(() => {
            this.target.chart.update({
                plotOptions: {
                    series: {
                        events: {
                            afterAnimate: this.fixOverlapLabels.bind(this),
                        },
                    },
                },
            });
        });
    }
}

UIChartColumn.registerPlugin('fix-overlap-labels', UIChartFixOverlapLabels);

export { UIChartFixOverlapLabels };
