import { dispatchCustomEvent } from './events.js';

/**
 * @memberof SharedComponents
 * @type {EventBus}
 * @alias eventBus
 */
class EventBus {
    /**
     * @param {string} name
     * @param {Function} callback
     */
    addEventListener(name, callback) {
        HTMLElement.prototype.addEventListener.apply(document.body, arguments);
    }

    /**
     * @param {string} name
     * @param {Function} callback
     */
    removeEventListener(name, callback) {
        HTMLElement.prototype.removeEventListener.apply(
            document.body,
            arguments
        );
    }

    /**
     * @param {string} name
     * @param {*} [detail] - the event data object or value
     * @param {boolean} [bubbles] - enable event bubbling. Enabled by default
     * @param {boolean} [cancelable] - enable preventing default action. Enabled by default
     */
    dispatchCustomEvent(name, detail, bubbles, cancelable) {
        dispatchCustomEvent(document.body, name, detail, bubbles, cancelable);
    }
}

export const eventBus = new EventBus();
