/**
 * @memberof SharedComponents
 * @class Keyboard
 * @alias keyboard
 */

/**
 * @memberof Keyboard
 * @enum {number}
 */
const keyCodes = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    HOME: 36,
    END: 35,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DEL: 46,
};

/**
 * We use only 0 and -1, never use higher tab-indexes rather than 0.
 * @enum {string}
 */
const TabIndex = {
    Active: '0',
    Inactive: '-1',
};

/**
 * Key directions for navigation with arrows. Used for accessibility/navigation.
 * @enum {number}
 */
const keyDirections = {
    [keyCodes.LEFT]: -1,
    [keyCodes.DOWN]: -1,
    [keyCodes.RIGHT]: 1,
    [keyCodes.UP]: 1,
};

/**
 * @memberof Keyboard
 * @param {KeyboardEvent} e
 * @param {number} code
 * @returns {boolean}
 */
const isKeyPressed = function (e, code) {
    const keyCode = e.which ? e.which : e.keyCode;
    return keyCode === code;
};

/**
 * @memberof Keyboard
 * @param {KeyboardEvent} e
 * @returns {boolean}
 */
const isEnterPressed = function (e) {
    return isKeyPressed(e, keyCodes.ENTER);
};

/**
 * @memberof Keyboard
 * @param {KeyboardEvent} e
 * @returns {boolean}
 */
const isEscapePressed = function (e) {
    return isKeyPressed(e, keyCodes.ESCAPE);
};

export {
    keyCodes,
    keyDirections,
    TabIndex,
    isKeyPressed,
    isEnterPressed,
    isEscapePressed,
};
