import { UIData } from '../../../shared-components/components/data/ui-data.js';
import styles from './ui-chart-options.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-options
 * @augments {UIData}
 * @alias UIChartOptions
 * @classdesc Represents a class for <code>ui-chart-options</code> element.
 * Sets the options for UIChart in JSON format which 100% compatible with
 * native Highcharts API.
 * NB! This component exists only for backwards compatibility purposes.
 * The best scenario to replaced it with UIData.
 * @example
 * <ui-chart-options>{ JSON }</ui-chart-options>
 */
class UIChartOptions extends UIData {
    /**
     * @inheritDoc
     */
    render() {
        this.type = 'application/json';
    }
}

UIChartOptions.defineElement('ui-chart-options', styles);
export { UIChartOptions };
