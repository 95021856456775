import { UIChart } from '../chart/ui-chart.js';
import { PFMMappings } from '../../plugins/vendor/pfm-mapping.js';
import styles from './ui-chart-sausage.css';
import { debounce } from '../../../shared-components/global/helpers.js';

/**
 * @memberof ChartsComponents
 * @element ui-chart-sausage
 * @augments {UIChart}
 * @alias UIChartSausage
 * @classdesc Represents a class for <code>ui-chart-bar</code> element.
 * Draws bar type chart.
 * @property {ArrayLike<UISeries>} uiSeries shortcut to ui-series children.
 * @slot {@type "ui-chart-sausage"}
 * @example
 * <ui-chart-sausage preset="interactive">
 *   <ui-series data="[77]" name="transport"></ui-series>
 *   <ui-series data="[10]" name="children"></ui-series>
 * </ui-chart-sausage>
 */
class UIChartSausage extends UIChart {
    /**
     * @readonly
     * @returns {IProps}
     */
    static get props() {
        return {
            children: {
                uiSeries: {
                    selector: 'ui-series',
                    multiple: true,
                },
            },
        };
    }
    /**
     * @private
     * @type {string}
     */
    get type() {
        return 'bar';
    }

    /**
     * @type {number}
     */
    get gap() {
        return this.constructor.BaseGrid / 4;
    }

    /**
     * @type {number}
     */
    get sum() {
        return this._sum || 0;
    }
    set sum(value) {
        this._sum = value;
    }

    /**
     * @type {object}
     */
    static get Categories() {
        return this._categories || PFMMappings;
    }

    /**
     * Can be set externally before render, PFM service compatible.
     * @param {object} values
     */
    static setCategories(values) {
        this._categories = values;
    }

    /**
     * @type {number}
     */
    static get ResizeDebounceTIme() {
        return 100;
    }

    /**
     * @param {string} name
     * @returns {object | null}
     */
    static getCategoryByName(name) {
        return Object.values(this.Categories).find(
            (category) => category.name === name
        );
    }

    /**
     * @private
     * @param {number} x
     * @returns {string}
     */
    static roundLabel(x) {
        const pct = Math.round(x * 10) / 10;
        const hasDecimals = pct - Math.floor(pct) !== 0;
        return pct.toFixed(hasDecimals ? 1 : 0);
    }

    /**
     * @type {Highcharts.Options}
     */
    get options() {
        /** @type {UIChart | UIChartSausage} */
        const instance = this.instance;
        const radius = this.constructor.BaseGrid / 2;
        return {
            chart: {
                height: this.constructor.BaseGrid * 10,
                margin: 0,
                spacing: 0,
                // we do resizing manually with debounce re-rendering,
                // this method is much more performant and give more expected
                // results. HighCharts resizing isn't very good out-of-box.
                // check handleWindowResize() for simple implementation.
                reflow: false,
                events: {
                    load: this.loadChartEventHandler.bind(this),
                    render: this.renderChartEventHandler.bind(this),
                },
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    // radii should be declared separated with plugin highcharts-roundedcorners,
                    borderRadiusTopRight: radius,
                    borderRadiusTopLeft: radius,
                    borderRadiusBottomRight: radius,
                    borderRadiusBottomLeft: radius,
                    pointWidth: this.constructor.BaseGrid * 3,
                    stacking: 'percent',
                    dataLabels: {
                        align: 'center',
                        verticalAlign: 'middle',
                        allowOverlap: true,
                        enabled: true,
                        overflow: 'allow',
                        useHTML: true,
                        formatter() {
                            return instance.dataLabelFormatter(this);
                        },
                    },
                },
            }, // end plotOptions
            yAxis: {
                reversed: true,
            },
        };
    }

    /**
     * @private
     * @param {object} dataLabel
     * @returns {Function}
     */
    dataLabelFormatter(dataLabel) {
        const originalPercentage = (dataLabel.point.y / this.sum) * 100;
        const pct = UIChartSausage.roundLabel(originalPercentage);
        const glyph =
            UIChartSausage.getCategoryByName(dataLabel.point.series.name)
                ?.icon || 'redesign-question-mark';
        return `
                <div class="ui-chart-sausage__label">
                    <ui-icon color="white">${glyph}</ui-icon>
                    <span>${pct}%</span>
                </div>`;
    }

    /**
     * @private
     */
    loadChartEventHandler() {
        requestAnimationFrame(() => this.redraw(false, false));
    }

    /**
     * @param {CustomEvent} event
     * @private
     */
    renderChartEventHandler(event) {
        const chart = event.target;
        const width = chart.chartWidth;
        if (width === 0) {
            console.warn('sausage chart width is zero');
            return;
        }
        // 2px is min width by design + 2 gaps = 2 + 2 + 2, so these look better
        const minWidth = this.gap * 3;
        const gapDelta = minWidth / width;
        for (let i = chart.series.length - 1; i >= 0; --i) {
            const point = chart.series[i].points[0];
            const dataLabel = point.dataLabel.div.querySelector(
                '.ui-chart-sausage__label'
            );
            const label = dataLabel.querySelector('span');
            const icon = dataLabel.querySelector('ui-icon');

            // NB! In bar stack charts dimensions are inverted!
            // dlBox comes from roundedcorners plugin to keep dimensions of the
            // original rectangle.
            const pw = point.dlBox?.height || 0;
            const lw = label.offsetWidth;

            // Hide if icon doesn't fit
            if (pw < lw) {
                label.textContent = label.textContent.replace(/[^0-9,.]/, '');
            }

            if (point.percentage < gapDelta * 100) {
                point.update({ y: gapDelta * this.sum }, false, false);
            }

            // make even better alignment
            if (pw < lw && pw >= this.constructor.BaseGrid * 3) {
                dataLabel.style.minWidth = `${pw + this.gap}px`;
            }

            const isLabelHidden = lw > pw || Math.round(point.percentage) === 0;
            const isIconHidden = pw < this.constructor.BaseGrid * 3;
            if (isLabelHidden) {
                label.classList.add('-visibility-hidden');
            }
            if (isIconHidden) {
                icon.classList.add('-visibility-hidden');
            }
        }
    }

    /**
     * @private
     */
    handleWindowResize() {
        this.renderChart();
    }

    /**
     * Processes the series.
     * Not very efficient, but this insures a stable sorting and resizing.
     * @param {boolean} [redraw]
     * @private
     */
    processUISeries(redraw = false) {
        this.initialSeries = this.initialSeries || [...this.uiSeries];
        try {
            this.initialSeries.sort(
                (a, b) => JSON.parse(b.data)[0] - JSON.parse(a.data)[0]
            );
        } catch (e) {
            console.error(
                `${this.constructor.name}: invalid series data: ${e}`
            );
            return;
        }
        this.append(...this.initialSeries);
        try {
            this.sum = [].reduce.call(
                this.uiSeries,
                (prev, next) => prev + JSON.parse(next.data)[0],
                0
            );
        } catch (e) {
            console.error(`${this.constructor.name}: wrong data: ${e}`);
            return;
        }
        redraw && this.redraw();
    }

    /**
     * @private
     */
    setSeriesColors() {
        [].forEach.call(this.initialSeries, (series) => {
            const name = series.getAttribute('name');
            const category = this.constructor.getCategoryByName(name);
            category &&
                series.style.setProperty(
                    '--ui-chart-sausage-series-color',
                    `var(${category.color})` || ''
                );
        });
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.processUISeries();
        this.setSeriesColors();
        this.draw(this.options);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        super.hydrate();
        this.windowResizeHandler = debounce(
            this.handleWindowResize.bind(this),
            this.constructor.ResizeDebounceTIme
        );
        window.addEventListener('resize', this.windowResizeHandler);
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        window.removeEventListener('resize', this.windowResizeHandler);
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        window.addEventListener('resize', this.windowResizeHandler);
    }
}

UIChartSausage.defineElement('ui-chart-sausage', styles);
export { UIChartSausage };
