import { UIChartColumn } from '../column/ui-chart-column.js';
import styles from './ui-chart-bar.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-bar
 * @augments {UIChartColumn}
 * @alias UIChartBar
 * @classdesc Represents a class for <code>ui-chart-bar</code> element.
 * Draws bar type chart.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-bar preset="interactive">
 *   <ui-series data="[10, 25, 50, 33, 43, 98]"></ui-series>
 *   <ui-series data="[77, 3, 32, 33 , 5.4]"></ui-series>
 * </ui-chart-bar>
 */
class UIChartBar extends UIChartColumn {
    get type() {
        return 'bar';
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.draw({
            chart: {
                // Fixes last xAxis label cut-off (stupid), but official
                // https://github.com/highcharts/highcharts/issues/10111
                marginRight: 15,
            },
            yAxis: {
                reversedStacks: false,
            },
            plotOptions: {
                series: {
                    enableMouseTracking: false,
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'justify',
                        padding: 6,
                        format: '{point.y}' + this.units,
                    },
                },
            },
        });
    }
}

UIChartBar.defineElement('ui-chart-bar', styles);
export { UIChartBar };
