import { UIChart } from '../chart/ui-chart.js';
import styles from './ui-chart-line.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-line
 * @augments {UIChart}
 * @alias UIChartLine
 * @classdesc Represents a class for <code>ui-chart-line</code> element.
 * Draws line type chart.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-line preset="interactive">
 *   <ui-series data="[10, 25, 50, 33, 43, 98]"></ui-series>
 *   <ui-series data="[77, 3, 32, 33 , 5.4]"></ui-series>
 * </ui-chart-line>
 */
class UIChartLine extends UIChart {
    get type() {
        return 'line';
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.mergeOptionsFromElement();
        this.draw({
            xAxis: {
                tickmarkPlacement: 'on',
            },
            tooltip: {
                enabled: true,
            },
            plotOptions: {
                series: {
                    enableMouseTracking: true,
                    dataLabels: {
                        enabled: false,
                    },
                    legendSymbol: 'lineMarker',
                },
            },
        });
    }
}

UIChartLine.defineElement('ui-chart-line', styles);
export { UIChartLine };
