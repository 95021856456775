import { UIElement } from '../../../shared-components/components/ui-element.js';
import {
    getBaseGridPoint,
    whenDOMReady,
} from '../../../shared-components/global/ui-helpers.js';
import Highcharts from 'highcharts/es-modules/masters/highstock.src.js';
import 'highcharts/es-modules/masters/highcharts-more.src.js';
import 'highcharts/es-modules/masters/modules/solid-gauge.src.js';
import 'highcharts/es-modules/masters/modules/accessibility.src.js';
import 'highcharts/es-modules/masters/modules/exporting.src.js';
import 'highcharts/es-modules/masters/modules/data.src.js';
import { UIIcon } from '../../../shared-components/components/icon/ui-icon.js';
import styles from './ui-chart.css';

/**
 * @memberof ChartsComponents
 * @abstract
 * @element ui-chart
 * @augments {UIElement}
 * @alias UIChart
 * @classdesc Represents a class for <code>ui-chart</code> element.
 * This is a basic abstract class for chart component. It has
 * main methods to work with charts. Can have children:
 * &lt;ui-chart-options>, &lt;ui-chart-series>.
 * as child elements and can be passed all possible Highchart API options.
 * @property {string} [units=""] {@attr units} Units of the chart labels
 *  like EUR, %, pcs, $ etc.
 * @property {("static" | "dynamic" | "interactive")} [preset="static"] {@attr preset}
 *  Presets with different options, check ui-chart-options.
 *  {@desc static: chart without any animation}
 *  {@desc dynamic: basic animations are added}
 *  {@desc interactive: fully animatable and clickable chart with rich features}
 * Chart renders only if it is visible on viewport.
 * @property {number} [height=400] {@attr height} Height of the chart.
 * @property {("area" | "areaspline" | "bar" | "column" | "line" | "lineargauge" | "pie" |
 *             "solidgauge" | "spline" )} [type="line"] {@attr type} Type of the chart.
 * {@desc area: Area chart}
 * {@desc areaspline: Area-spline chart}
 * {@desc bar: Bar chart}
 * {@desc line: Line chart}
 * {@desc lineargauge: Linear gauge chart}
 * {@desc pie: Pie chart}
 * {@desc solidgauge: Solid gauge chart}
 * {@desc spline: Spline chart}
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart preset="interactive" type="bar">
 *   <ui-series data="[10, 25, 50, 33, 43]"></ui-series>
 * </ui-chart>
 */
class UIChart extends UIElement {
    /**
     * @type {IProps}
     */
    static get props() {
        return {
            attributes: {
                units: { type: String, default: '' },
                preset: { type: String, default: 'dynamic' },
                height: { type: Number, default: 400 },
            },
        };
    }

    get type() {
        return this.getAttribute('type') || 'line';
    }

    set type(value) {
        this.setAttribute('type', value);
    }

    get stock() {
        return false;
    }

    get container() {
        if (!this._container) {
            this._container = this.createContainer();
        }
        return this._container;
    }

    /**
     * @returns {UIChart}
     */
    get instance() {
        return this;
    }

    get chart() {
        // Danger! Check this very-very carefully. It might have infinite loop.
        if (!this._chart) {
            this.renderChart();
        }
        return this._chart;
    }

    /**
     * Numbers for colors
     * @returns {number}
     */
    static get COLORS_COUNT() {
        return 17;
    }

    /**
     * @type {number}
     * @protected
     */
    static get BaseGrid() {
        if (!this._gridBasepoint) {
            this._gridBasepoint = getBaseGridPoint();
        }
        return this._gridBasepoint;
    }

    /**
     * @returns {Highcharts.Options}
     * @private
     * @static
     */
    static get _a11yConfig() {
        return {
            accessibility: {
                keyboardNavigation: {
                    focusBorder: {
                        enabled: false, // reset highcharts specific focus and use global.
                        hideBrowserFocusOutline: false, // <...>
                        styles: {},
                    },
                },
            },
        };
    }

    /**
     * Predefined presets for charts.
     * <ul class="regular-list">
     *   <li><b>static</b> - chart without any animation;</li>
     *   <li><b>dynamic</b> - basic animations are added;</li>
     *   <li><b>interactive</b> - fully animatable and clickable chart with rich features.</li>
     * </ul>
     * @type {{
     *   static: Highcharts.Options,
     *   dynamic: Highcharts.Options,
     *   interactive: Highcharts.Options
     * }}
     */
    static get presets() {
        return {
            static: {
                ...UIChart._a11yConfig,
                chart: {
                    animation: false,
                    zooming: {
                        mouseWheel: false,
                    },
                },
                legend: {
                    navigation: {
                        enabled: false,
                    },
                    itemHoverStyle: {
                        color: null,
                    },
                },
                plotOptions: {
                    series: {
                        animation: false,
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                        point: {
                            events: {
                                legendItemClick: () => false,
                            },
                        },
                        events: {
                            legendItemClick: () => false,
                        },
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
            dynamic: {
                ...UIChart._a11yConfig,
                chart: {
                    animation: {
                        enabled: true,
                    },
                    zooming: {
                        mouseWheel: false,
                    },
                },
                legend: {
                    navigation: {
                        enabled: false,
                    },
                    itemHoverStyle: {
                        color: true,
                    },
                },
                plotOptions: {
                    series: {
                        animation: {
                            enabled: true,
                        },
                        states: {
                            hover: {
                                enabled: true,
                            },
                        },
                        point: {
                            events: {
                                legendItemClick: () => false,
                            },
                        },
                        events: {
                            legendItemClick: () => false,
                        },
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
            interactive: {
                ...UIChart._a11yConfig,
                chart: {
                    animation: {
                        enabled: true,
                    },
                    zooming: {
                        mouseWheel: false,
                    },
                },
                legend: {
                    enabled: true,
                    navigation: {
                        enabled: true,
                    },
                },
                plotOptions: {
                    series: {
                        allowPointSelect: false,
                        animation: {
                            enabled: true,
                        },
                        states: {
                            hover: {
                                enabled: true,
                            },
                        },
                        point: {
                            events: {
                                legendItemClick: () => true,
                            },
                        },
                        events: {
                            legendItemClick: () => true,
                        },
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
        };
    }

    /**
     * Formats HTML lengend items with value.
     * @param {string} name
     * @param {number} value
     * @returns {string}
     * @private
     */
    formatLegendLabelsWithValues(name, value) {
        return `
      <div>
        <div class="legend-with-value">${value.toFixed(2)}</div>
        <div class="legend-category-label">${name}</div>
      </div>`;
    }

    /**
     * Custom legend label formatter for legend with icons and without them.
     * @param {UIChart} current
     * @returns {string}
     */
    static customLegendFormatter(current) {
        let html;
        const options = current.getOptions();
        if (!options.series) {
            return '';
        }
        const series = options.series[0];
        const getIcon = () => {
            if (
                series.data &&
                series.data[this.index] &&
                series.data[this.index].icon
            ) {
                return series.data[this.index].icon;
            }
            if (series.icon) {
                return series.icon;
            }
            return null;
        };

        const icon = getIcon();
        if (icon) {
            current.setAttribute('with-icons', true);
            if (this.series.chart.options.legend.itemsWithValues) {
                html = current.formatLegendLabelsWithValues(
                    this.name,
                    -series.data[this.index].y
                );
            } else {
                html = this.name;
            }
            const className = series.data[this.index].className;
            const computedClass = className
                ? className
                : 'highcharts-color-' + this.colorIndex;
            const caption = current.createElement({
                tagName: 'ui-caption',
                attributes: {
                    layout: 'chip',
                    position: 'right',
                    color: computedClass,
                },
            });
            caption.setInnerText(html, true);
            caption.insertElements([
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: icon,
                        size: 'medium',
                        class: computedClass,
                    },
                },
            ]);
            return caption.outerHTML;
        } else {
            return this.name;
        }
    }

    /**
     * Remap value from one range to another.
     * @param {number} value
     * @param {number} min1
     * @param {number} max1
     * @param {number} min2
     * @param {number} max2
     * @returns {number}
     */
    static remap(value, min1, max1, min2, max2) {
        return ((value - min1) * (max2 - min2)) / (max1 - min1) + min2;
    }

    /**
     * Calculates coordinates on circle based on radius and angle.
     * Also sets small offsets to better position.
     * @param {number} radius Raidus of a circle.
     * @param {number} theta Angle between X-axis and line.
     * @param {number} offsetX
     * @param {number} offsetY
     * @returns {{x: number, y: number}}
     */
    static calculateCircleCoords(radius, theta, offsetX, offsetY) {
        return {
            x: (radius + radius * offsetX) * Math.cos(theta),
            y: (radius + radius * offsetY) * Math.sin(theta),
        };
    }

    /**
     * Calculates sum for given series.
     * @param {Highcharts.Series} series
     * @returns {number}
     * @private
     */
    calculateSeriesSum(series) {
        return series.points.reduce((sum, point) => sum + point.y, 0);
    }

    /**
     * Calculates percent of point value from given series.
     * NOTE: can be replaced in HC 11.1.0 with point.percentage.
     * @param {Highcharts.Series|object} series
     * @param {number} pointVal
     * @returns {number}
     * @private
     */
    calculatePointPercentage(series, pointVal) {
        const sum = this.calculateSeriesSum(series);
        return sum ? (pointVal / sum) * 100 : 0;
    }

    /**
     * Create menu item with icon for exporting module.
     * Since 9 ver. of Highcharts cannot be used inside config
     * @param {string} text
     * @param {string} icon
     * @returns {string}
     * @private
     */
    createExportingMenuItem(text, icon) {
        const button = this.createElement({
            tagName: 'button',
            classList: {
                '-iconed': true,
            },
            children: [
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: icon,
                        bgcolor: UIIcon.colors.TURQUOISE,
                    },
                },
            ],
        });
        return button.outerHTML + text;
    }

    /**
     * Creates container for chart.
     * @private
     * @returns {HTMLDivElement}
     */
    createContainer() {
        this._container = this.createElement({
            tagName: 'div',
            classList: {
                'ui-chart__container': true,
            },
        });
        this.appendChild(this._container);
        return this._container;
    }

    /**
     * Merges options from <ui-chart-options>...</ui-chart-options>.
     */
    mergeOptionsFromElement() {
        [].forEach.call(this.querySelectorAll('ui-chart-options'), (opt) => {
            // #bug: Safari renderer doesn't render element in tree deep.
            // So some nested components are rendered before parent or visa-versa.
            // Object.setPrototypeOf(opt, UIChartOptions.prototype);
            opt.type = 'application/json';

            const json = opt.get();
            this.setOptions(json);
        });
    }

    /**
     * Merges presets with options.
     * @returns {boolean}
     * @private
     */
    resolvePresets() {
        if (!this.preset) {
            return false;
        }
        this.setOptions(UIChart.presets[this.preset]);
        return true;
    }

    /**
     * Sets custom the config for UIChart instance.
     * @param {Highcharts.Options} opts
     */
    setOptions(opts) {
        this.opts = Highcharts.merge(this.opts, opts);
    }

    /**
     * Gets options for chart. Mostly is used for debugging purposes.
     * @returns {Highcharts.Options}
     */
    getOptions() {
        return this.opts;
    }

    /**
     * Gets children series.
     * @returns {NodeList<UISeries>}
     */
    fetchSeries() {
        return this.querySelectorAll('ui-series');
    }

    /**
     * Gets data from series objects.
     */
    getDataFromSeries() {
        const series = this.fetchSeries();
        if (series.length > 0) {
            this.opts.series = [];
        }
        [].forEach.call(series, (ser) => {
            // #bug: see method UIChart.prototype.mergeOptionsFromElement();
            // Object.setPrototypeOf(ser, UISeries.prototype);
            this.opts.series.push(ser.toJSON());
        });
        this.setOptions(this.opts.series);
    }

    /**
     *  Adds an event listener. Wrapper function to Highcharts.
     * @param {string} type The event type. Possible event types are:
     * <ul class="regular-list">
     *   <li>addSeries</li>
     *   <li>afterPrint</li>
     *   <li>beforePrint</li>
     *   <li>click</li>
     *   <li>drilldown</li>
     *   <li>drillup</li>
     *   <li>drillupall</li>
     *   <li>exportData</li>
     *   <li>load</li>
     *   <li>redraw</li>
     *   <li>render</li>
     *   <li>selection</li>
     * </ul>
     * @param {Highcharts.EventCallbackFunction | Function} fn The function
     * callback to execute when the event is fired.
     * @param {Highcharts.EventOptionsObject} options Options for adding
     * the event.
     */
    addEvent(type, fn, options) {
        Highcharts.addEvent(this.chart, type, fn, options);
    }

    /**
     * Draws the chart.
     * @param {Highcharts.Options} [opts] Any possible options from Highcharts API.
     */
    draw(opts = {}) {
        const currentChart = this.instance;
        this.setOptions({
            chart: {
                renderTo: this.container,
                type: this.type,
                styledMode: true,
                colorCount: UIChart.COLORS_COUNT,
                height: this.height,
            },
            exporting: {
                enabled: false,
                allowHTML: true,
                buttons: {
                    contextButton: {
                        enabled: true,
                        symbol: 'menuball',
                        menuItems: [
                            // 'viewFullscreen',
                            // 'printChart',
                            // 'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG',
                        ],
                    },
                },
                menuItemDefinitions: {
                    downloadPNG: {
                        text: 'PNG',
                    },
                    downloadJPEG: {
                        text: 'JPEG',
                    },
                    downloadSVG: {
                        text: 'SVG',
                    },
                    downloadPDF: {
                        text: 'PDF',
                    },
                },
            },
            title: {
                align: 'left',
                text: '',
            },
            legend: {
                enabled: false,
                useHTML: false,
                itemHoverStyle: {
                    color: null,
                },
                navigation: {
                    enabled: false,
                },
                labelFormatter: function () {
                    return UIChart.customLegendFormatter.call(
                        this,
                        currentChart
                    );
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    animation: false,
                    states: {
                        hover: {
                            enabled: true,
                        },
                    },
                    point: {
                        events: {
                            legendItemClick: () => false,
                        },
                    },
                    events: {
                        legendItemClick: () => false,
                    },
                },
                allowPointSelect: false,
            },
            tooltip: {
                enabled: false,
                valueSuffix: this.units,
            },
            xAxis: {
                visible: false,
                allowDecimals: false,
                labels: {
                    distance: 10,
                    padding: 5,
                    // autoRotation: [0]
                },
            },
            yAxis: {
                visible: false,
                allowDecimals: false,
                minRange: 0.1,
            },
        });
        this.resolvePresets();
        this.mergeOptionsFromElement();
        this.getDataFromSeries();
        this.setOptions(opts || {});

        const dataOptions = this.getOptions().data;
        if (dataOptions) {
            if (dataOptions.csvURL) {
                this.setOptions({
                    data: {
                        csvURL: dataOptions.csvURL,
                    },
                });
            }
            if (dataOptions.rowsURL) {
                this.setOptions({
                    data: {
                        rowsURL: dataOptions.rowsURL,
                    },
                });
            }
        }

        this.stock
            ? (this._chart = new Highcharts.StockChart(this.getOptions()))
            : (this._chart = new Highcharts.Chart(this.getOptions()));
    }

    /**
     * Redraws the chart.
     * @param {boolean} [redraw]
     * @param {boolean} [animation]
     * @returns {boolean}
     */
    redraw(redraw = true, animation = true) {
        return this._chart ? this._chart.redraw(redraw, animation) : false;
    }

    /**
     * Main method fo chart rendering. Every chart's type has own
     * renderChart() method. This method should be overridden.
     */
    renderChart() {
        this.draw(this.getOptions());
    }

    /**
     * @inheritDoc
     */
    render() {
        // Charts should be rendered only when DOM is fully ready.
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        // Render chart async.
        // Problem can be checked on the ibanks page:
        // private/cards/cards/debit - Sustainability tab
        whenDOMReady(this.renderChart.bind(this), false);
    }
}

UIChart.defineElement('ui-chart', styles);
export { UIChart };
