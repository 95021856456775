/**
 * Dispatches custom event.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {string} name - the event name
 * @param {*} [detail] - the event data object or value
 * @param {boolean} [bubbles] - enable event bubbling. Enabled by default
 * @param {boolean} [cancelable] - enable preventing default action. Enabled by default
 * @returns {CustomEvent}
 */
const dispatchCustomEvent = (element, name, detail, bubbles, cancelable) => {
    const event = new CustomEvent(name, {
        bubbles: bubbles !== false,
        cancelable: cancelable !== false,
        detail: detail,
    });
    element.dispatchEvent(event);
    return event;
};

/**
 * Dispatches native event.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {string} name - the event name
 * @param {boolean} [bubbles] - enable event bubbling. Enabled by default
 * @returns {Event}
 */
const dispatchNativeEvent = (element, name, bubbles) => {
    const event = new CustomEvent(name, { bubbles: bubbles !== false });
    element.dispatchEvent(event);
    return event;
};

export { dispatchCustomEvent, dispatchNativeEvent };
