import './plugins/vendor/highcharts-custom-events.js';
import './plugins/vendor/highcharts-roundedcorners.js';

export * from './components/index.js';
export * from './plugins/index.js';

import Highcharts from 'highcharts/es-modules/masters/highstock.src.js';

// window.Highcharts = Highcharts; fix for Highcharts v9.1.0:
// inside error handling global Highcharts object is used in the code sources
// check page with charts with ajax/csv data

// Allowed custom tags in Highcharts.
['ui-caption', 'ui-icon'].forEach((tag) => {
    Highcharts.AST.allowedTags.push(tag);
});

// Allowed custom attributes in Highcharts.
['state', 'glyph', 'position', 'color', 'bgcolor', 'layout', 'size'].forEach(
    (attr) => {
        Highcharts.AST.allowedAttributes.push(attr);
    }
);

export { Highcharts };
