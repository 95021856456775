import { UIChartLine } from '../line/ui-chart-line.js';
import styles from './ui-chart-spline.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-spline
 * @augments {UIChartLine}
 * @alias UIChartSpline
 * @classdesc Represents a class for <code>ui-chart-spline</code> element.
 * Draws spline type chart.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-spline preset="interactive">
 *   <ui-series data="[10, 25, 50, 33, 43, 98]"></ui-series>
 *   <ui-series data="[77, 3, 32, 33 , 5.4]"></ui-series>
 * </ui-chart-spline>
 */
class UIChartSpline extends UIChartLine {
    get type() {
        return 'spline';
    }
}

UIChartSpline.defineElement('ui-chart-spline', styles);
export { UIChartSpline };
