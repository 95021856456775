import { UIChartBar } from '../bar/ui-chart-bar.js';
import styles from './ui-chart-lineargauge.css';

/**
 * @memberof ChartsComponents
 * @element ui-chart-lineargauge
 * @augments {UIChart}
 * @alias UIChartLinearGauge
 * @classdesc Represents a class for <code>ui-chart-lineargauge</code> element.
 * Draws lineargauge type chart.
 * @property {boolean} percentage {@attr percentage} Display value as percent.
 * @slot {@type "ui-series"}
 * @example
 * <ui-chart-lineargauge units="EUR" height="125">
 *   <ui-series name="Expenses" data="[82]" class="highcharts-color-4"></ui-series>
 * </ui-chart-lineargauge>
 */
class UIChartLinearGauge extends UIChartBar {
    get percentage() {
        return this.getAttribute('percentage');
    }
    set percentage(value) {
        const v = this.parseBooleanAttr(value);
        if (v) {
            this.setAttribute('percentage', 'percentage');
        } else {
            this.removeAttribute('checked');
        }
    }

    /**
     * @inheritDoc
     */
    renderChart() {
        this.mergeOptionsFromElement();
        if (this.opts && this.opts.yAxis) {
            this.min = this.opts.yAxis.min || 0;
            this.max = this.opts.yAxis.max || 100;
        } else {
            this.min = 0;
            this.max = 100;
        }
        const { max, percentage, units } = this;

        this.draw({
            chart: {
                marginRight: 0,
            },
            percentage,
            xAxis: {
                visible: true,
                opposite: true,
                labels: {
                    useHTML: true,
                    align: 'right',
                    x: 0,
                    y: 60,
                    formatter: function () {
                        return (
                            '<div class="highcharts-data-label lineargauge-axis-numeric-label">' +
                            max +
                            ' ' +
                            units +
                            '</div>' +
                            '<div class="lineargauge-axis-label">' +
                            this.opts.series[0].name +
                            '</div>'
                        );
                    }.bind(this),
                },
                plotBands: [
                    {
                        from: -0.248,
                        to: 0.248,
                    },
                ],
            },
            yAxis: {
                min: this.min,
                max: this.max,
            },
            plotOptions: {
                series: {
                    borderRadius: 2,
                    dataLabels: {
                        enabled: true,
                        useHTML: true,
                        x: -20,
                        y: -83 /* -74 - 4 -5 (padding)  highcharts 8.1.1+ issue */,
                        formatter: function () {
                            if (percentage) {
                                return (
                                    Math.floor((this.point.y / max) * 100) + '%'
                                );
                            }
                            return this.point.y + ' ' + units;
                        },
                    },
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                        },
                        chartOptions: {
                            plotOptions: {
                                series: {
                                    pointWidth: 30,
                                    dataLabels: {
                                        y: -24,
                                    },
                                },
                            },
                            xAxis: {
                                plotBands: [
                                    {
                                        from: -0.17,
                                        to: 0.163,
                                    },
                                ],
                            },
                        },
                    },
                ],
            },
        });
    }
}

UIChartLinearGauge.defineElement('ui-chart-lineargauge', styles);
export { UIChartLinearGauge };
