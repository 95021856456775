import '../sprite/ui-sprite.js';
import { UIElement } from '../ui-element.js';
import styles from './ui-icon.css';
import { rebuildChildren, createElement } from '../../global/ui-helpers.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIIcon
 * @element ui-icon
 * @classdesc Represents a class for <code>ui-icon</code> element.
 * One attribute is mandatory <b>glyph</b> or <b>src</b>.
 * attribute should present one of them. You cannot use both of them.
 * @property {UIIconGlyph} glyph {@attr glyph} Represents glyph (symbol) icon
 * from library provided by branding.
 * @property {string} src {@attr src} URL to custom icon, can be SVG, PNG, JPEG etc.
 * @property {UIIconColor} [color] {@attr color} Foreground color from library to be set on icon.
 * @property {UIIconColor} [bgcolor] {@attr bgcolor}
 * Background color from library to be set on icon.
 * @property {("small" | "medium" | "large")} [size] {@attr size} Size of the icon
 *  {@desc small}
 *  {@desc medium}
 *  {@desc large}
 * @property {UIIconGlyph} [badgeGlyph] {@attr badge-glyph} Glyph (symbol) icon
 * from library provided by branding for badge.
 * @property {HTMLSpanElement} badge {@readonly} Container for badge icon.
 * @example
 * <ui-icon color="turquoise" glyph="calendar"></ui-icon>
 */
class UIIcon extends UIElement {
    /**
     * Possible color values
     * @returns {object}
     */
    static get colors() {
        return {
            DEFAULT: 'default',
            ORANGE: 'orange',
            ORANGE_TEXT: 'orange-text',
            PINEAPPLE: 'pineapple',
            TURQUOISE: 'turquoise',
            TEAL: 'teal',
            SEAFOAM: 'seafoam',
            LILAC: 'lilac',
            TULIP: 'tulip',
            LAVENDER: 'lavender',
            LISTGREY: 'listgrey',
            GREY: 'grey',
            WHITE: 'white',
            ALERT_RED: 'alert-red',
            ALERT_GREEN: 'alert-green',
            ALERT_BLUE: 'alert-blue',
            ALERT_YELLOW: 'alert-yellow',
            TANGERINE: 'tangerine',
            APRICOT: 'apricot',
            ROSE: 'rose',
            BARK_70: 'bark-70',
            BARK_60: 'bark-60',
            BARK_50: 'bark-50',
            BARK_30: 'bark-30',
            BARK: 'bark' /* should deprecate */,
            MEDIUMBROWN: 'mediumbrown' /* should deprecate */,
            DARKVANILLA: 'darkvanilla' /* should deprecate */,
            SOFTBROWN: 'softbrown' /* should deprecate */,
            LIGHTERBARK: 'lighterbark' /* should deprecate */,
        };
    }

    /**
     * Deprecated color values
     * @returns {Array<string>}
     */
    static get deprecatedColors() {
        return [
            'brown',
            'orange-wcag',
            'yellow',
            'info-yellow',
            'blue',
            'turquoise-wcag',
            'info-turquoise',
            'lilac-wcag',
            'info-lilac',
            'info-pink',
            'info-blue',
            'info-mediumbrown',
            'lightgray',
            'info-brown',
            'info-gray',
            'gray',
            'red',
            'info-green',
            'alert-success',
            'bark',
            'darkvanilla',
            'mediumbrown',
            'softbrown',
            'lighterbark',
        ];
    }

    static get observedAttributes() {
        return ['glyph', 'src', 'badge-glyph', 'bgcolor', 'color'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                glyph: String,
                bgcolor: {
                    type: String,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component,
                        value
                    ) => {
                        const info =
                            `Allowed options are: default, bark-70, orange, orange-text, lilac, ` +
                            `pineapple, turquoise, teal, seafoam, tulip, tangerine, lavender, rose, ` +
                            `listgrey, grey, white, alert-red, alert-green, alert-blue, alert-yellow, apricot, ` +
                            `bark-60, bark-50, bark-30.`;
                        if (UIIcon.deprecatedColors.includes(value)) {
                            console.warn(
                                `${value} - color is deprecated. ${info}`,
                                component
                            );
                            return;
                        }
                        if (
                            !UIIcon.colors[
                                value.toUpperCase().replace('-', '_')
                            ]
                        ) {
                            console.warn(
                                `${value} - value does not exist. ${info}`,
                                component
                            );
                        }
                    },
                },
                color: {
                    type: String,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component,
                        value
                    ) => {
                        const info =
                            `Allowed options are: default, bark-70, orange, orange-text, lilac, ` +
                            `pineapple, turquoise, teal, seafoam, tulip, tangerine, lavender, rose, ` +
                            `listgrey, grey, white, alert-red, alert-green, alert-blue, alert-yellow, apricot, ` +
                            `bark-60, bark-50, bark-30.`;
                        if (UIIcon.deprecatedColors.includes(value)) {
                            console.warn(
                                `${value} - color is deprecated. ${info}`,
                                component
                            );
                            return;
                        }
                        if (
                            !UIIcon.colors[
                                value.toUpperCase().replace('-', '_')
                            ]
                        ) {
                            console.warn(
                                `${value} - value does not exist. ${info}`,
                                component
                            );
                        }
                    },
                },
                src: String,
                size: String,
                badgeGlyph: String,
            },
            children: {
                badge: '.ui-icon__badge',
            },
        };
    }

    /**
     * Layouts enum
     * @type {{CUSTOM: string, DEFAULT: string}}
     */
    static get layouts() {
        return {
            CUSTOM: 'custom',
            DEFAULT: 'default',
        };
    }

    /**
     * Get sprite element
     * @returns {UISprite}
     */
    static getSprite() {
        // Share it globally
        if (!this._sprite) {
            this._sprite = document.querySelector('ui-sprite');
        }
        return this._sprite;
    }

    /**
     * Get ui-icon current layout
     * @returns {string}
     */
    getLayout() {
        if (this.src) {
            return UIIcon.layouts.CUSTOM;
        }
        return UIIcon.layouts.DEFAULT;
    }

    /**
     * Checks that icon is a custom image from external source.
     * @returns {boolean}
     */
    isCustomImage() {
        return String(this.src).match(/(svg|jpg|jpeg|bmp|webp|png)$/i) !== null;
    }

    /**
     * Build glyph id for svg.
     * @param {UIIconGlyph} [id]
     * @returns {string}
     */
    buildGlyphId(id) {
        const sprite = UIIcon.getSprite();
        return (
            (sprite ? sprite.getGlyphPrefix() : '') +
            '#ui-glyph-' +
            (id || this.glyph)
        );
    }

    /**
     * Build and return config for image element from src attribute.
     * @private
     * @returns {IElementConfig}
     */
    buildImage() {
        return {
            tagName: 'img',
            attributes: {
                'aria-hidden': 'true',
                src: this.src,
                alt: '',
            },
        };
    }

    /**
     * Build and return config for SVG element.
     * @private
     * @param {UIIconGlyph} [id]
     * @returns {IElementConfig}
     */
    buildSvg(id) {
        return {
            tagName: 'svg',
            attributes: {
                'aria-hidden': 'true',
            },
            children: [
                {
                    tagName: 'use',
                    attributes: {
                        'xlink:href': this.buildGlyphId(id),
                    },
                },
            ],
        };
    }

    /**
     * Build and return badge element config.
     * @returns {IElementConfig}
     */
    buildBadge() {
        return {
            tagName: 'span',
            classList: {
                'ui-icon__badge': true,
            },
            children: [this.buildSvg(this.badgeGlyph)],
        };
    }

    /**
     * Render or rebuild ui-icon mark up.
     */
    renderIconLayout() {
        this.glyph?.split('-')[0] === 'redesign' &&
            this.setAttribute('redesign', '');
        const layout = this.getLayout();
        const children = /** @type {Array<IElementConfig | Text>} */ [];
        switch (layout) {
            case UIIcon.layouts.CUSTOM:
                if (this.isCustomImage()) {
                    children.push(this.buildImage());
                }
                break;
            default:
                if (this.textContent || this.glyph) {
                    if (this.textContent) {
                        this.glyph = this.glyph || this.textContent;
                        this.innerHTML = '';
                    }
                    children.push(this.buildSvg());
                }
                break;
        }

        if (this.badgeGlyph) {
            children.push(this.buildBadge());
        }

        this.rebuildChildren(children);
        this.classList.toggle('-badged', !!this.badgeGlyph);
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'glyph':
                if (this.querySelector('svg')) {
                    this.renderIconLayout();
                }
                break;
            case 'src':
                if (this.querySelector('img')) {
                    this.renderIconLayout();
                }
                break;
            case 'badge-glyph':
                this.classList.toggle('-badged', !!newValue);

                if (!newValue && this.badge) {
                    this.removeChild(this.badge);
                }
                if (newValue) {
                    let el = this.badge;
                    if (!el) {
                        el = createElement({
                            tagName: 'span',
                            classList: {
                                'ui-icon__badge': true,
                            },
                        });
                        this.appendChild(el);
                    }
                    rebuildChildren(this.badge, this.buildBadge().children);
                }
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.renderIconLayout();
    }
}

UIIcon.defineElement('ui-icon', [styles]);
export { UIIcon };
